import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { GlobalValuesProvider } from "../globalValues"
import styled, { css } from "styled-components"
import { CTAButton } from "../components/CTAButton"
import { Text } from "../components/Text"
import { RichText } from "../components/RichText"
import { breakpoints, ThemeProvider } from "../styles"
import { Image } from "../components/Image"
import { HEADER_HEIGHTS } from "../components/Header"
import {
  COLOR_GRAY_900,
  SPACE_2XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../styles/primitives"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"
import { useHoverState } from "../components/useHoverState"

const TRANSITION_DELAY = 200

const IMAGE_ASPECT_RATIO = 2139 / 1820
const IMAGE_HEIGHT_VH = { SM: 35, MD: 24, LG: 33, XL: 50 }
const IMAGE_BOTTOM_PADDING_TO_WHEELS = 0.20854 + 0.05
const IMAGE_TOP_PADDING_TO_HEADLIGHT = 0.4966
const IMAGE_RIGHT_PADDING_TO_HEADLIGHT = 0.16266

const floorStopY = {
  SM:
    IMAGE_HEIGHT_VH.SM -
    IMAGE_BOTTOM_PADDING_TO_WHEELS * IMAGE_HEIGHT_VH.SM +
    10,
  MD:
    IMAGE_HEIGHT_VH.MD +
    (100 - IMAGE_HEIGHT_VH.MD) / 2 -
    IMAGE_BOTTOM_PADDING_TO_WHEELS * IMAGE_HEIGHT_VH.MD,
  LG:
    IMAGE_HEIGHT_VH.LG +
    (100 - IMAGE_HEIGHT_VH.LG) / 2 -
    IMAGE_BOTTOM_PADDING_TO_WHEELS * IMAGE_HEIGHT_VH.LG,
  XL:
    IMAGE_HEIGHT_VH.XL +
    (100 - IMAGE_HEIGHT_VH.XL) / 2 -
    IMAGE_BOTTOM_PADDING_TO_WHEELS * IMAGE_HEIGHT_VH.XL,
}

export const query = graphql`
  query NotFoundPageQuery($mainContentfulId: String!, $node_locale: String!) {
    ...GlobalValues
    contentfulMain(
      contentful_id: { eq: $mainContentfulId }
      node_locale: { eq: $node_locale }
    ) {
      ...ContentfulMainMainLinkFragment
      ...ContentfulMainFooterData
      ...ContentfulMainHeaderData
      ...ContentfulLocaleModalData

      page404Title
      page404MetaDescription
      page404Image {
        gatsbyImageData
        description
      }
      page404Headline {
        page404Headline
      }
      page404BackButton {
        ...CTAFragment
      }
      page404Description {
        raw
        references {
          __typename
          ...RichtextReferencesModalFragment
          ...RichtextReferencesCmsPageFragment
          ...RichtextReferencesAssetFragment
          ...RichtextReferencesPressReleaseFragment
        }
      }
    }
  }
`

const NotFoundPage = ({ data, pageContext }) => {
  const { language, country, footerData } = pageContext
  const textContainerHoverState = useHoverState()
  const headlightHoverState = useHoverState()

  const {
    page404Title,
    page404MetaDescription,
    page404Image,
    page404Headline,
    page404BackButton,
    page404Description,
  } = data.contentfulMain

  return (
    <GlobalValuesProvider value={data.contentfulGlobal}>
      <ThemeProvider themeName="dark">
        <Layout
          language={language}
          country={country}
          mainData={data.contentfulMain}
          footerData={footerData}
        >
          <SEO
            title={page404Title}
            description={page404MetaDescription}
            localizedNodes={[]}
          />
          {/* <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "red",
              width: "100px",
              height: "100px",
            }}
          /> */}

          <Root
            showHeadlightEffect={
              textContainerHoverState.isHovered || headlightHoverState.isHovered
            }
          >
            <UpperGradientPart />
            <BottomGradientPart />
            <ImageContainer>
              <Headlight
                onPointerEnter={headlightHoverState.onPointerEnter}
                onPointerLeave={headlightHoverState.onPointerLeave}
              />
              <SImage asset={page404Image} objectFit="contain" />
            </ImageContainer>
            <TextContainer>
              <InnerTextContainer
                onPointerEnter={textContainerHoverState.onPointerEnter}
                onPointerLeave={textContainerHoverState.onPointerLeave}
              >
                <Headline type="primary">
                  {page404Headline.page404Headline}
                </Headline>
                <DescriptionText type="tertiary">
                  <RichText data={page404Description} />
                </DescriptionText>
                <SCTAButton cta={page404BackButton} />
              </InnerTextContainer>
            </TextContainer>
          </Root>
        </Layout>
      </ThemeProvider>
    </GlobalValuesProvider>
  )
}

export default NotFoundPage

const gradientParts = [
  `linear-gradient(180deg, #2b2b2b 0%, #212121 100%)`,
  `linear-gradient(180deg, ${COLOR_GRAY_900}   0%, ${COLOR_GRAY_900} 100%)`,
]

const UpperGradientPart = styled.div`
  background: ${gradientParts[0]};
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  ${breakpoints({
    SM: css`
      bottom: calc(${100 - floorStopY.SM}vh - ${HEADER_HEIGHTS.XL}px);
    `,
    MD: css`
      bottom: calc(${100 - floorStopY.MD}vh - ${HEADER_HEIGHTS.XL}px);
    `,
    LG: css`
      bottom: calc(${100 - floorStopY.LG}vh - ${HEADER_HEIGHTS.LG}px);
    `,
    XL: css`
      bottom: calc(${100 - floorStopY.XL}vh - ${HEADER_HEIGHTS.XL}px);
    `,
  })}
`

const BottomGradientPart = styled.div`
  background: ${gradientParts[1]};
  z-index: -2;
  position: absolute;
  left: 0;
  right: 0;

  ${breakpoints({
    SM: css`
      top: ${floorStopY.SM}vh;
      bottom: 0;
    `,
    MD: css`
      top: ${floorStopY.MD}vh;
      bottom: 0;
    `,
    LG: css`
      top: ${floorStopY.LG}vh;
      bottom: 0;
    `,
    XL: css`
      top: ${floorStopY.XL}vh;
      bottom: 0;
    `,
  })}
`

const ImageContainer = styled.div`
  grid-area: image;
  align-self: center;
  position: relative;
  justify-self: flex-end;
  ${breakpoints({
    SM: css`
      justify-self: center;
    `,
  })}
`

const SImage = styled(Image)`
  pointer-events: none;

  ${breakpoints({
    SM: css`
      max-width: 100vw;
      height: ${IMAGE_HEIGHT_VH.SM}vh;
      width: ${IMAGE_HEIGHT_VH.SM * IMAGE_ASPECT_RATIO}vh;
    `,
    MD: css`
      height: ${IMAGE_HEIGHT_VH.MD}vh;
      width: ${IMAGE_HEIGHT_VH.MD * IMAGE_ASPECT_RATIO}vh;
    `,
    LG: css`
      height: ${IMAGE_HEIGHT_VH.LG}vh;
      width: ${IMAGE_HEIGHT_VH.LG * IMAGE_ASPECT_RATIO}vh;
    `,
    XL: css`
      height: ${IMAGE_HEIGHT_VH.XL}vh;
      width: ${IMAGE_HEIGHT_VH.XL * IMAGE_ASPECT_RATIO}vh;
    `,
  })}
`

const Headline = styled(Text)`
  white-space: pre-wrap;
  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  transition-delay: ${TRANSITION_DELAY}ms;
`

const DescriptionText = styled(Text)`
  margin-top: ${SPACE_XL}px;
  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  transition-delay: ${TRANSITION_DELAY}ms;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_M}px;
    `,
  })}
`

const SCTAButton = styled(CTAButton)`
  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  transition-delay: 0ms;
  width: 100%;
  align-self: center;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_M}px;
      align-self: flex-start;
    `,
    MD: css`
      margin-top: ${SPACE_XL}px;
      align-self: flex-start;
    `,
    LG: css`
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
    `,
    XL: css`
      max-width: 450px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    MAX: css`
      max-width: 450px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
  })};
`

const TextContainer = styled.div`
  grid-area: text;
  align-self: center;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_XL}px;
    `,
    MD: css`
      padding: ${SPACE_L}px;
    `,
    LG: css`
      padding: ${SPACE_L}px;
    `,
    XL: css`
      padding: ${SPACE_XL}px;
    `,
  })}
`

const InnerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

function createHeadlightPositionStyles(imageHeightVH) {
  return css`
    top: calc(
      50% -
        ${0.5 * imageHeightVH -
        IMAGE_TOP_PADDING_TO_HEADLIGHT * imageHeightVH}vh
    );
    left: calc(
      100% -
        (
          ${IMAGE_RIGHT_PADDING_TO_HEADLIGHT} * ${IMAGE_ASPECT_RATIO} *
            ${imageHeightVH}vh
        )
    );
  `
}

const Headlight = styled.div`
  position: absolute;
  width: 70vw;
  height: 70vw;
  transform-origin: top left;
  transform: rotate(-45deg) skew(45deg, 45deg);
  margin-left: -15px;

  opacity: 0;
  transition: opacity ${ANIMATION_DURATION}ms ${ANIMATION_EASING},
    transform ${ANIMATION_DURATION}ms ${ANIMATION_EASING};

  background: radial-gradient(
      208.13% 155.45% at -163.56% -14.27%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      144.7% 64.44% at -59.42% 17.53%,
      rgba(255, 205, 5, 0.196) 10.42%,
      rgba(255, 255, 255, 0) 92.71%
    );

  ${breakpoints({
    SM: css`
      display: none;
    `,
    MD: css`
      display: none;
    `,
    LG: css`
      ${createHeadlightPositionStyles(IMAGE_HEIGHT_VH.LG)};
    `,
    XL: css`
      ${createHeadlightPositionStyles(IMAGE_HEIGHT_VH.XL)};
    `,
  })}
`

const headlightEffectStyles = ({ showHeadlightEffect }) =>
  showHeadlightEffect &&
  css`
    ${Headlight} {
      opacity: 1;
      transform: rotate(-45deg) skew(0deg, 0deg);
    }

    ${TextContainer} {
      cursor: default;

      ${DescriptionText}, ${Headline} {
        opacity: 0;
        transition-delay: 0ms;
      }

      ${SCTAButton} {
        transition-delay: ${TRANSITION_DELAY}ms;
        opacity: 1;
      }
    }
  `

const Root = styled.div`
  display: grid;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_2XL}px;
      min-height: calc(100vh - ${HEADER_HEIGHTS.SM}px);
      grid-template-areas:
        "image"
        "text";
      grid-template-rows: max-content auto;
    `,
    MD: css`
      min-height: calc(100vh - ${HEADER_HEIGHTS.MD}px);

      grid-template-areas:
        "image text"
        "image text";
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 45% 55%;
    `,
    LG: css`
      min-height: calc(100vh - ${HEADER_HEIGHTS.LG}px);

      grid-template-areas:
        "image text"
        "image text";
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 50% 50%;
    `,
    XL: css`
      min-height: calc(100vh - ${HEADER_HEIGHTS.XL}px);

      grid-template-areas:
        "image text"
        "image text";
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 50% 50%;
    `,
  })};

  ${breakpoints({
    LG: headlightEffectStyles,
    XL: headlightEffectStyles,
  })}
`
